<template>
  <div class="raidersLeft_page">
    <img src="../../sprite/strategy-bg1.jpg" alt="">
    <img src="../../sprite/strategy-bg2.jpg" alt="">
    <img src="../../sprite/strategy-bg3.jpg" alt="">
    <img src="../../sprite/strategy-bg4.jpg" alt="">
    <img src="../../sprite/strategy-bg6.jpg" alt="">
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.raidersLeft_page {
  position: relative;
  img{
    width: 100%;
    display: block;
    margin:0 auto;
  }
}
</style>
